@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

* {
    font-family: 'Cairo', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.css-13cymwt-control {
    height: 45px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}